import { Box, type SxProps, useTheme } from '@mui/material';
import { ReactComponent as BackspaceIcon } from '@/assets/icons/backspace.svg';
import NumberPadButton from './NumberPadButton';

type NumberPadProps = {
  sx?: SxProps;
  customButtonLabel?: string;
  onClick: (input: string) => void;
  onDelete: () => void;
};

const NumberPad = ({
  sx,
  customButtonLabel,
  onClick,
  onDelete,
}: NumberPadProps) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(4, 1fr)',
        background: theme.palette.grey[200],
        gap: '1px',
      }}
    >
      {numbers.map((number) => (
        <NumberPadButton
          key={number}
          onClick={() => {
            onClick(number.toString());
          }}
        >
          {number}
        </NumberPadButton>
      ))}
      <NumberPadButton
        key={'custom'}
        disabled={!customButtonLabel}
        onClick={() => {
          onClick(customButtonLabel ?? '');
        }}
      >
        {customButtonLabel}
      </NumberPadButton>
      <NumberPadButton
        key={'0'}
        onClick={() => {
          onClick('0');
        }}
      >
        0
      </NumberPadButton>
      <NumberPadButton
        key={'backspace'}
        onClick={() => {
          onDelete();
        }}
      >
        <BackspaceIcon />
      </NumberPadButton>
    </Box>
  );
};

export default NumberPad;
