import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  type DriverLicenseType,
  ManagerApprovalType,
  RESERVATION_VALIDATE_LICENSE_ERROR_CODE,
  type ReservationDto,
  type ValidateReservationDriverLicenseAndUpdateDto,
} from '@orcar/common';
import { captureException, withScope } from '@sentry/electron';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getBlackLists } from '@/apis/black-list.api';
import BottomButtons from '@/components/BottomButtons';
import ConfirmDialog from '@/components/ConfirmDialog';
import LoadingDialog from '@/components/LoadingDialog';
import TitleMessage from '@/components/TitleMessage';
import { DRIVER_LICENSE_VALIDATION_ERROR } from '@/constant/error.constant';
import { useManagerApproval } from '@/hooks/manager-approval.hook';
import {
  useGetReservation,
  useUploadDriverLicenseImageAndUpdateUrl,
  useValidateAndUpdateDriverLicense,
} from '@/hooks/reservation.hook';
import {
  compareLicenseNumbers,
  extractTextFromImage,
} from '@/utils/tesseract-ocr.util';
import BlackListManagerApproval from './components/BlackListManagerApproval';
import DriverLicenseValidationManagerApproval from './components/DriverLicenseValidationManagerApproval';
import KeypadWrapper, { type KeypadType } from './components/KeypadWrapper';
import ReservationPersonCard from './components/ReservationPersonCard';
import { type KeypadState } from './DriverInfoPage';

const DEFAULT_ERROR_MESSAGE = '오류가 발생했어요.\n카운터에 문의해주세요.';

export type DriverLicenseForm = {
  name: string;
  phoneNumber: string;
  birthDate: string;
  driverLicenseType: DriverLicenseType | null;
  driverLicenseNumber: string;
  isBirthDateExtracted: boolean;
  isDriverLicenseNumberExtracted: boolean;
  needToReScan?: boolean;
};

export type DriverLicenseImageForm = {
  licenseImage: Blob | null;
  isApproved: boolean;
};

type ErrorDialogState = {
  open: boolean;
  message: string;
  type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR;
};

type Props = {
  reservation: ReservationDto;
  driverNumber: 1 | 2;
};

const DriverLicenseInfo: FC<Props> = ({ reservation, driverNumber }) => {
  const navigate = useNavigate();

  const [openLoading, setOpenLoading] = useState(false);

  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
    open: false,
    message: '',
  });
  const [keypadState, setKeypadState] = useState<KeypadState>({
    open: false,
    type: 'name',
    editSingleType: false,
  });

  const [openAskDriverApproval, setOpenAskDriverApproval] = useState(false);
  const [askDriverApprovalTitle, setAskDriverApprovalTitle] = useState('');
  const [askDriverApprovalMessage, setAskDriverApprovalMessage] = useState('');

  const [openAskBlackListApproval, setOpenAskBlackListApproval] =
    useState(false);
  const [askBlackListApprovalTitle, setAskBlackListApprovalTitle] =
    useState('');
  const [askBlackListApprovalMessage, setAskBlackListApprovalMessage] =
    useState('');

  const [openAskSecondDriver, setOpenAskSecondDriver] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);

  const errorCount = useRef(0);

  const { mutateAsync: validateAndUpdateDriverLicense } =
    useValidateAndUpdateDriverLicense();
  const { mutateAsync: uploadDriverLicenseImageAndUpdateUrl } =
    useUploadDriverLicenseImageAndUpdateUrl();

  const { start, stop, status } = useManagerApproval(
    ManagerApprovalType.SKIP_DRIVER_LICENSE_VALIDATION,
    reservation.id,
  );

  const {
    start: startBlackList,
    stop: stopBlackList,
    status: statusBlackList,
  } = useManagerApproval(ManagerApprovalType.BLACK_LIST, reservation.id);

  const person = useMemo(
    () => (driverNumber === 1 ? reservation.contractor : reservation.driver),
    [driverNumber, reservation],
  );

  const driverLicenseForm = useForm<DriverLicenseForm>();
  const { reset, setError, setValue } = driverLicenseForm;

  const driverLicenseImageForm = useForm<DriverLicenseImageForm>();
  const { reset: resetImage } = driverLicenseImageForm;

  useEffect(() => {
    reset({
      name:
        person.name || (driverNumber === 1 && reservation.customerName1) || '',
      phoneNumber:
        person.phoneNumber ||
        (driverNumber === 1 && reservation.customerPhoneNumber1) ||
        '',
      birthDate: person.birthDate
        ? dayjs(person.birthDate).format('YYYYMMDD')
        : '',
      driverLicenseType: person.driverLicenseType ?? null,
      driverLicenseNumber: person.driverLicenseNumber ?? '',
      isBirthDateExtracted: person.isBirthDateExtracted ?? false,
      isDriverLicenseNumberExtracted:
        person.isDriverLicenseNumberExtracted ?? false,
    });
  }, [driverNumber, person, reservation, reset]);

  useEffect(() => {
    resetImage({
      licenseImage: null,
      isApproved: false,
    });
  }, [resetImage, driverNumber]);

  useEffect(() => {
    setOpenLoading(false);
    setErrorDialogState({ open: false, message: '' });
    setOpenAskDriverApproval(false);
    setAskDriverApprovalTitle('');
    setAskDriverApprovalMessage('');
    setOpenAskSecondDriver(false);
    setNeedHelp(false);
    errorCount.current = 0;
    stop();
    stopBlackList();
  }, [driverNumber, stop, stopBlackList]);

  const handleSubmit = useCallback(
    async (
      passAmbiguousDriverExperience = false,
      passBlackList = false,
    ): Promise<void> => {
      const {
        name,
        birthDate,
        driverLicenseNumber,
        phoneNumber,
        driverLicenseType,
        isBirthDateExtracted,
        isDriverLicenseNumberExtracted,
      } = driverLicenseForm.getValues();

      const { isApproved, licenseImage } = driverLicenseImageForm.getValues();

      if (!isApproved && !licenseImage && !person.driverLicenseImageUrl) {
        throw handleOpenError('운전면허증 스캔을 완료해주세요.');
      } else if (!name) {
        throw handleOpenError('이름을 입력해주세요.');
      } else if (birthDate.length !== 8) {
        throw handleOpenError('생년월일을 입력해주세요.');
      } else if (phoneNumber.length < 10) {
        throw handleOpenError('연락처를 확인해주세요.');
      } else if (!driverLicenseType) {
        throw handleOpenError(
          '운전면허 종별을 선택해주세요.',
          DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_LICENSE_TYPE,
        );
      } else if (driverLicenseNumber.length !== 12) {
        throw handleOpenError(
          '운전면허번호를 확인해주세요.',
          DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_LICENSE_NUMBER,
        );
      }

      setOpenLoading(true);

      try {
        const result = await extractTextFromImage(licenseImage);

        if (result && !isApproved && licenseImage) {
          const { recognizedLicenseNumber, recognizedBirthDate } = result;

          if (!recognizedLicenseNumber) {
            const reader = new FileReader();
            reader.onload = function () {
              const arrayBuffer = this.result;

              withScope((scope) => {
                if (arrayBuffer instanceof ArrayBuffer) {
                  const unit8Array = new Uint8Array(arrayBuffer);
                  scope.addAttachment({
                    data: unit8Array,
                    filename: `reservation-${reservation.id}-driver-${driverNumber}.jpg`,
                    contentType: 'image/jpeg',
                  });
                }
                scope.setExtras({
                  driverLicenseNumber: driverLicenseNumber.slice(2, 10),
                  recognizedBirthDate,
                  recognizedLicenseNumber,
                });
                captureException(
                  new Error('스캔된 이미지에서 면허번호 추출을 실패했습니다.'),
                );
              });
            };
            reader.readAsArrayBuffer(licenseImage);
            throw handleOpenError(
              `면허증의 면허번호를 인식하지 못했습니다.\n다시 스캔해주세요.`,
              DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_EXTRACT_LICENSE_NUMBER,
            );
          }
          const isAlmostSameLicenseNumber = compareLicenseNumbers(
            recognizedLicenseNumber,
            driverLicenseNumber.slice(2, 10),
          );

          if (!isAlmostSameLicenseNumber) {
            const reader = new FileReader();
            reader.onload = function () {
              const arrayBuffer = this.result;

              withScope((scope) => {
                if (arrayBuffer instanceof ArrayBuffer) {
                  const unit8Array = new Uint8Array(arrayBuffer);
                  scope.addAttachment({
                    data: unit8Array,
                    filename: `reservation-${reservation.id}-driver-${driverNumber}.jpg`,
                    contentType: 'image/jpeg',
                  });
                }
                scope.setExtras({
                  driverLicenseNumber: driverLicenseNumber.slice(2, 10),
                  recognizedBirthDate,
                  recognizedLicenseNumber,
                });
                captureException(
                  new Error('스캔된 이미지와 면허정보가 일치하지 않습니다.'),
                );
              });
            };
            reader.readAsArrayBuffer(licenseImage);
            throw handleOpenError(
              `면허증과 면허번호가 일치하지 않습니다.`,
              DRIVER_LICENSE_VALIDATION_ERROR.MISMATCHED_LICENSE_NUMBER,
            );
          }
        }

        try {
          await validateAndUpdateDriverLicense({
            id: reservation.id,
            input: {
              driverNumber,
              input: {
                name,
                birthDate,
                phoneNumber,
                driverLicenseType,
                driverLicenseNumber,
                isBirthDateExtracted,
                isDriverLicenseNumberExtracted,
                ...(isApproved ? { driverLicenseImageUrl: null } : null),
                skipDriverLicenseValidation: errorCount.current > 1,
                passAmbiguousDriverExperience,
                recognizedBirthDate:
                  result && result.recognizedBirthDate
                    ? result.recognizedBirthDate
                    : 'failed to extract',
                recognizedLicenseNumber:
                  result && result.recognizedLicenseNumber
                    ? result.recognizedLicenseNumber
                    : 'failed to extract',
              } as ValidateReservationDriverLicenseAndUpdateDto,
            },
          });
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 400) {
            switch (error.response.data.code) {
              case RESERVATION_VALIDATE_LICENSE_ERROR_CODE.VALIDATION_FAILED:
                const message =
                  error.response.data.message || '알 수 없는 오류';
                switch (message) {
                  case '면허번호 없음':
                    throw handleOpenError(
                      '존재하지 않는 운전면허번호입니다. 다시 확인해주세요.',
                      DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_LICENSE_NUMBER,
                    );
                  case '정보불일치(이름)':
                    throw handleOpenError(
                      `이름이 면허번호와 일치하지 않습니다.\n 이름을 확인해주세요.`,
                      DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_NAME,
                    );
                  case '정보불일치(생년월일)':
                    throw handleOpenError('생년월일을 확인해주세요.');
                  case '정보불일치(종별)':
                    throw handleOpenError(
                      '운전면허 종별을 확인해주세요.',
                      DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_LICENSE_TYPE,
                    );
                  default:
                    throw handleOpenError(
                      `운전면허 정보를 확인해주세요.\n${message}`,
                    );
                }
              case RESERVATION_VALIDATE_LICENSE_ERROR_CODE.AGE_LIMIT:
                throw handleOpenError(
                  '나이 제한으로 인해 대여가 불가능합니다.',
                );
              case RESERVATION_VALIDATE_LICENSE_ERROR_CODE.EXPERIENCE_LIMIT:
                throw handleOpenError(
                  `운전 경력 제한으로 인해 대여가 불가능합니다.\n다른 운전자를 등록해주세요.`,
                  DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION,
                );
              case RESERVATION_VALIDATE_LICENSE_ERROR_CODE.LICENSE_TYPE:
                throw handleOpenError(
                  '면허 종별 제한으로 인해 대여가 불가능합니다.\n다른 운전자를 등록해주세요',
                  DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION,
                );
              case RESERVATION_VALIDATE_LICENSE_ERROR_CODE.EXPERIENCE_AMBIGUOUS:
                setAskDriverApprovalTitle('담당 직원의 확인이 필요합니다.');
                setAskDriverApprovalMessage(error.response.data.message);
                setOpenAskDriverApproval(true);
                throw new Error();
              default:
                throw handleOpenError(DEFAULT_ERROR_MESSAGE);
            }
          } else {
            if (++errorCount.current > 1) {
              return await handleSubmit(passAmbiguousDriverExperience);
            }

            throw handleOpenError(
              '운전자 정보 검증 중 오류가 발생했어요.\n다시 시도해주세요.',
            );
          }
        }

        if (passBlackList !== true) {
          try {
            const { data } = await getBlackLists({
              nameKeyword: name,
              driverLicenseNumberKeyword: driverLicenseNumber.slice(0, 10),
              isOwned: true,
            });

            if (
              data.length > 0 &&
              data.some((blackList) => blackList.isKioskRentRestricted)
            ) {
              throw new Error();
            }
          } catch (error) {
            setAskBlackListApprovalTitle(
              '예약을 완료하기 위해 추가 확인이 필요합니다.',
            );
            setAskBlackListApprovalMessage(
              '가까운 직원에게 도움을 요청해주세요.',
            );
            setOpenAskBlackListApproval(true);
            throw new Error();
          }
        }

        if (licenseImage) {
          try {
            await uploadDriverLicenseImageAndUpdateUrl({
              id: reservation.id,
              input: {
                driverNumber,
                imageBlob: licenseImage,
              },
            });
          } catch (error) {
            throw handleOpenError(DEFAULT_ERROR_MESSAGE);
          }
        }
      } finally {
        setOpenLoading(false);
      }
    },
    [
      person,
      driverLicenseForm,
      driverLicenseImageForm,
      driverNumber,
      reservation,
      validateAndUpdateDriverLicense,
      uploadDriverLicenseImageAndUpdateUrl,
    ],
  );

  const afterSubmit = useCallback(() => {
    if (driverNumber === 1) {
      if (reservation.driver.name) {
        navigate(`/${reservation.id}/driver-info/2`);
      } else {
        setOpenAskSecondDriver(true);
      }
    } else {
      navigate(`/${reservation.id}/contract-info`);
    }
  }, [driverNumber, navigate, reservation]);

  const onSubmit = async () => {
    try {
      await handleSubmit();
      afterSubmit();
    } catch {}
  };

  const handleOpenError = (
    message: string,
    type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR,
  ) => {
    setErrorDialogState({ open: true, message, type });
    return new Error(message);
  };

  const handleSetError = (
    type?: keyof typeof DRIVER_LICENSE_VALIDATION_ERROR,
  ) => {
    switch (type) {
      case DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_NAME:
        navigate(`/${reservation.id}/driver-info/${driverNumber}`, {
          state: {
            error: {
              message: '이름을 확인해주세요.',
              type: DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_NAME,
            },
          },
          replace: true,
        });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_LICENSE_TYPE:
        setError('driverLicenseType', {
          message: '면허종류를 다시 확인해주세요.',
        });
      case DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_LICENSE_TYPE:
        setError('driverLicenseType', { message: '면허종류를 선택해주세요.' });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_VERIFY_LICENSE_NUMBER:
      case DRIVER_LICENSE_VALIDATION_ERROR.REQUIRE_LICENSE_NUMBER:
        setError('driverLicenseNumber', {
          message: '면허번호를 다시 확인해주세요.',
        });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.FAILED_TO_EXTRACT_LICENSE_NUMBER:
        setError('needToReScan', { message: '다시 스캔해주세요.' });
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.MISMATCHED_LICENSE_NUMBER:
        setError('driverLicenseNumber', {
          message: '면허번호를 다시 확인해주세요.',
        });
        setNeedHelp(true);
        break;
      case DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION:
        navigate(`/${reservation.id}/driver-info/${driverNumber}`, {
          state: {
            error: {
              type: DRIVER_LICENSE_VALIDATION_ERROR.NOT_SATISFIED_REQUIRE_CONDITION,
            },
          },
          replace: true,
        });
        break;
      default:
        break;
    }
  };

  const handleConfirmKeypad = (
    type: KeypadType,
    value?: string,
    licenseInfo?: { licenseType: DriverLicenseType; licenseNumber: string },
  ) => {
    if (licenseInfo) {
      setValue('driverLicenseType', licenseInfo.licenseType);
      setValue('driverLicenseNumber', licenseInfo.licenseNumber);
      setKeypadState((prev) => ({ ...prev, open: false }));
      if (!keypadState.editSingleType) {
        onSubmit();
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage
          message={`${
            driverNumber === 2 ? '제2 ' : ''
          }운전자 면허 정보를 확인해 주세요.`}
        />
        <Box sx={{ height: '56px' }} />
        <ReservationPersonCard
          type='driverLicenseInfo'
          reservationId={reservation.id}
          driverNumber={driverNumber}
          person={person}
          driverLicenseForm={driverLicenseForm}
          driverLicenseImageForm={driverLicenseImageForm}
          isKeypadOpen={keypadState.open}
          setKeypadState={setKeypadState}
        />
        {needHelp && (
          <Box
            sx={{
              position: 'relative',
              mt: 4,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <ErrorOutlineIcon
              htmlColor='#7e7e7e'
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 40,
                height: 40,
              }}
            />
            <Typography
              sx={{
                ml: 6,
                fontSize: 32,
                fontWeight: 'regular',
                color: 'text.secondary',
                whiteSpace: 'pre-line',
              }}
            >
              {`면허증과 입력한 정보가 같은 지 확인해주세요.\n두 정보가 일치하는데도 오류가 발생한다면, 카운터로 문의해주세요.`}
            </Typography>
          </Box>
        )}
        {/* {driverNumber === 1 && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              background: theme.palette.grey[50],
              border: '1px solid #d4d4d4',
              marginTop: 4,
              paddingX: '40px',
              paddingY: '20px',
            })}
          >
            <Typography
              fontSize={32}
              fontWeight={'regular'}
              color={'text.secondary'}
              lineHeight={1.5}
            >
              계약서에 등록한 운전자만 운전이 가능합니다.
              <br />
              (미등록 운전자 사고 시 보험처리 불가)
            </Typography>
            <Box flexGrow={1} />

            <Box>
              <ChipButton
                onClick={async () => {
                  try {
                    await handleSubmit();
                    navigate(`/${reservation.id}/driver-info/2`);
                  } catch {}
                }}
              >
                제2 운전자 등록
              </ChipButton>
            </Box>
          </Box>
        )} */}
      </Box>
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClickBack={() =>
          navigate(`/${reservation.id}/driver-info/${driverNumber}`, {
            replace: true,
          })
        }
        onClick={onSubmit}
      />
      <ConfirmDialog
        open={openAskSecondDriver}
        title={'제2 운전자를 등록하시겠습니까?'}
        confirmButtonLabel='예'
        closeButtonLabel='아니오'
        onConfirm={() => {
          setOpenAskSecondDriver(false);
          navigate(`/${reservation.id}/driver-info/2`);
        }}
        onClose={() => {
          setOpenAskSecondDriver(false);
          navigate(`/${reservation.id}/contract-info`);
        }}
        onClickOutside={() => {
          setOpenAskSecondDriver(false);
        }}
      />
      <ConfirmDialog
        open={openAskDriverApproval}
        title={askDriverApprovalTitle}
        confirmButtonLabel='관리자 확인'
        closeButtonLabel='취소'
        onClose={() => {
          setOpenAskDriverApproval(false);
        }}
        onConfirm={() => {
          setOpenAskDriverApproval(false);
          start(async () => {
            try {
              await handleSubmit(true);
              afterSubmit();
            } catch {}
          });
        }}
        onClickOutside={() => {
          setOpenAskDriverApproval(false);
        }}
      >
        <Box
          sx={{
            padding: '28px',
            paddingBottom: '40px',
            fontSize: 32,
            fontWeight: 'regular',
            color: 'text.secondary',
            lineHeight: 1.5,
            whiteSpace: 'pre-line',
          }}
        >
          {askDriverApprovalMessage}
        </Box>
      </ConfirmDialog>

      <Dialog
        open={status === 'WAITING'}
        PaperProps={{
          sx: {
            position: 'relative',
            padding: 0,
          },
        }}
      >
        <DriverLicenseValidationManagerApproval onClosed={stop} />
      </Dialog>
      <ConfirmDialog
        open={openAskBlackListApproval}
        title={askBlackListApprovalTitle}
        confirmButtonLabel='관리자 확인'
        closeButtonLabel='취소'
        onClose={() => {
          setOpenAskBlackListApproval(false);
        }}
        onConfirm={() => {
          setOpenAskBlackListApproval(false);
          startBlackList(async () => {
            try {
              await handleSubmit(true, true);
              afterSubmit();
            } catch {}
          });
        }}
        onClickOutside={() => {
          setOpenAskBlackListApproval(false);
        }}
      >
        <Box
          sx={{
            padding: '28px',
            paddingBottom: '40px',
            fontSize: 32,
            fontWeight: 'regular',
            color: 'text.secondary',
            lineHeight: 1.5,
            whiteSpace: 'pre-line',
          }}
        >
          {askBlackListApprovalMessage}
        </Box>
      </ConfirmDialog>
      <Dialog
        open={statusBlackList === 'WAITING'}
        PaperProps={{
          sx: {
            position: 'relative',
            padding: 0,
          },
        }}
      >
        <BlackListManagerApproval onClosed={stopBlackList} />
      </Dialog>
      <Dialog open={errorDialogState.open}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5, whiteSpace: 'pre-line' }}
          >
            {errorDialogState.message}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => {
              const { type } = errorDialogState;
              handleSetError(type);
              setErrorDialogState({ open: false, message: '' });
            }}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
      <LoadingDialog
        open={openLoading}
        content='운전자 정보를 확인하고 있어요.'
      />
      <KeypadWrapper
        open={keypadState.open}
        type={keypadState.type}
        editSingleType={keypadState.editSingleType}
        driverNumber={driverNumber}
        driverLicenseForm={driverLicenseForm}
        setType={(type: KeypadType) =>
          setKeypadState((prev) => ({ ...prev, type }))
        }
        onClose={() => {
          setKeypadState((prev) => ({ ...prev, open: false }));
        }}
        onConfirm={handleConfirmKeypad}
      />
    </Box>
  );
};

const DriverLicenseInfoPage: FC = () => {
  const { id, driverNumber: driverNumberString } = useParams<{
    id: string;
    driverNumber: '1' | '2';
  }>();

  const { data: reservation, isLoading } = useGetReservation({ id });

  const driverNumber =
    driverNumberString === '1' ? 1 : driverNumberString === '2' ? 2 : null;

  if (isLoading) {
    return <></>;
  }

  if (!driverNumber || !reservation) {
    return <Navigate to='/' replace />;
  }

  return (
    <DriverLicenseInfo reservation={reservation} driverNumber={driverNumber} />
  );
};

export default DriverLicenseInfoPage;
