var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean } from 'class-validator';
export class CreateVehicleModelOptionDto {
}
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "leatherSeat", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "noSmoke", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "navigation1", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "navigation2", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "navigation3", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "blackBox", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "spareTire", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "seatHeater", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "seatHeater2", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "sunroof", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "driverSideAirbag", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "powerSeat", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "electricTrunk", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "passengerSideAirbag", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "pneumaticPump", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "temporaryTire", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "ventilatedSeat1", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "ventilatedSeat2", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "heatedSteeringWheel", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "parkingSensor", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "parkingCamera", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "ABS", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "ISG", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "EPB", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "FCA", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "RCCA", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateVehicleModelOptionDto.prototype, "LKA", void 0);
