import {
  Box,
  Checkbox as MuiCheckBox,
  type SxProps,
  Typography,
} from '@mui/material';
import { ReactComponent as CheckedIcon } from '@/assets/icons/check_box_checked.svg';
import { ReactComponent as UncheckedIcon } from '@/assets/icons/check_box_unchecked.svg';

type CheckBoxProps = {
  label?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  sx?: SxProps;
  fontSize?: number;
};

const CheckBox = ({
  label,
  checked,
  onChange,
  sx,
  fontSize,
}: CheckBoxProps) => {
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <MuiCheckBox
        sx={{
          height: '100%',
          '& .MuiSvgIcon-root': {
            fontSize: fontSize,
          },
        }}
        checked={checked}
        onChange={(e) => {
          onChange && onChange?.(e.target.checked);
        }}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
      />
      <Typography
        fontSize={fontSize}
        sx={{
          marginLeft: '10px',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default CheckBox;
