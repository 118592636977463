import { Box, Button, type SxProps, TextField } from '@mui/material';
import { useRef } from 'react';
import { ReactComponent as CancelIcon } from '@/assets/icons/cancel.svg';

export type TextInputProps = {
  sx?: SxProps;
  textFieldSx?: SxProps;
  inputSx?: SxProps;
  value: string;
  type?: string;
  onChange: (value: string) => void;
};

const TextInput = ({
  sx,
  textFieldSx,
  inputSx,
  value,
  type,
  onChange,
}: TextInputProps) => {
  const inputRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      <TextField
        ref={inputRef}
        value={value}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        sx={textFieldSx}
        inputProps={{
          sx: {
            marginRight: '80px',
            ...inputSx,
          },
        }}
        variant='outlined'
        type={type}
      />
      {value && (
        <Button
          sx={{
            position: 'absolute',
            top: (inputRef.current?.clientHeight ?? 0) / 2 - 32,
            width: '64px',
            height: '64px',
            right: '40px',
            ':hover': {
              background: 'transparent',
            },
          }}
          endIcon={<CancelIcon />}
          onClick={() => {
            onChange('');
          }}
        ></Button>
      )}
    </Box>
  );
};

export default TextInput;
