export var VehicleModelType;
(function (VehicleModelType) {
    VehicleModelType["URBAN"] = "URBAN";
    VehicleModelType["CITY"] = "CITY";
    VehicleModelType["COMPACT"] = "COMPACT";
    VehicleModelType["MIDSIZE"] = "MIDSIZE";
    VehicleModelType["RV"] = "RV";
    VehicleModelType["SUV"] = "SUV";
    VehicleModelType["LUXURY"] = "LUXURY";
    VehicleModelType["IMPORTED"] = "IMPORTED";
    VehicleModelType["EV"] = "EV";
})(VehicleModelType || (VehicleModelType = {}));
