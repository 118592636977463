export class VehicleModelOptionDto {
    constructor(initializer) {
        this.leatherSeat = initializer.leatherSeat;
        this.noSmoke = initializer.noSmoke;
        this.navigation1 = initializer.navigation1;
        this.navigation2 = initializer.navigation2;
        this.navigation3 = initializer.navigation3;
        this.blackBox = initializer.blackBox;
        this.spareTire = initializer.spareTire;
        this.seatHeater = initializer.seatHeater;
        this.seatHeater2 = initializer.seatHeater2;
        this.sunroof = initializer.sunroof;
        this.driverSideAirbag = initializer.driverSideAirbag;
        this.powerSeat = initializer.powerSeat;
        this.electricTrunk = initializer.electricTrunk;
        this.passengerSideAirbag = initializer.passengerSideAirbag;
        this.pneumaticPump = initializer.pneumaticPump;
        this.temporaryTire = initializer.temporaryTire;
        this.ventilatedSeat1 = initializer.ventilatedSeat1;
        this.ventilatedSeat2 = initializer.ventilatedSeat2;
        this.heatedSteeringWheel = initializer.heatedSteeringWheel;
        this.parkingSensor = initializer.parkingSensor;
        this.parkingCamera = initializer.parkingCamera;
        this.ABS = initializer.ABS;
        this.ISG = initializer.ISG;
        this.EPB = initializer.EPB;
        this.FCA = initializer.FCA;
        this.RCCA = initializer.RCCA;
        this.LKA = initializer.LKA;
    }
}
